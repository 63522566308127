import React, { FC, useState, useEffect, useRef, ChangeEvent } from 'react';
import * as Server from '../server';
import { parse, ParseResult, unparse } from 'papaparse';
import { IImportResult, IUser } from '../models';
import { Button, Checkbox, FormControlLabel, Typography } from '@mui/material'
import { DataGrid, GridState, MuiEvent, GridRowId } from '@mui/x-data-grid'
import FileSaver from 'file-saver';
import _ from "lodash";
import BasicModal from './BasicModal';
import { columns, columnsByField, columnsByLabel } from './Columns';

const Users: FC = () => {
    const [users, setUsers] = useState<IUser[]>([]);
    const [filteredUsers, setFilteredUsers] = useState<IUser[]>([]);
    const [importResult, setImportResult] = useState<IImportResult | null>(null);
    const [showImportResults, setShowImportResults] = useState<boolean>(false);
    const [includeDeleted, setIncludeDeleted] = useState<boolean>(false);
    const inputFileControl = useRef<HTMLInputElement | null>(null);
    const isFirstRender = useRef(true);
    let filteredRowLookup: Record<GridRowId, boolean> | null = null;

    const filterUsers = React.useCallback(() =>
    {
        const filtered: IUser[] = [];
        if (users) {
            for (const user of users) {
                if (!user.deleted || includeDeleted) {
                    filtered.push(user);
                }
            }
        }

        setFilteredUsers(filtered);
    }, [includeDeleted, users]);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            refreshUsers();
        }
        else {
            filterUsers();
        }
    }, [filterUsers]);

    const refreshUsers = async () => {
        Server.getUsers().then(res => {
            setUsers(res)
        });
    }

    const onImportFile = (event: ChangeEvent) => {
        event.stopPropagation();
        event.preventDefault();
        const target = event.target as HTMLInputElement;
        if (target.files) {
            let file = target.files[0];
            parse(file, {
                header: true,
                dynamicTyping: true,
                skipEmptyLines: true,
                transformHeader: (header) => {
                    const column = columnsByLabel.get(header);
                    if (column) {
                        return column.field;
                    }
                    return header;
                },
                transform: (value) => {
                    if (value === 'Yes') {
                        return true;
                    }
                    else if (value === 'No') {
                        return false;
                    }
                    return value;
                },
                complete: async function(results: ParseResult<IUser>, file) {
                    const payload = _.map(results.data, (x) => _.omitBy(x, _.isNil) as IUser);
                    const result = await Server.addUsers(payload);
                    refreshUsers();
                    setImportResult(result);
                    setShowImportResults(true);
                }
            });
        }
    }

    const chooseImportFile = () => {
        if (inputFileControl.current) {
            inputFileControl.current.value = '';
            inputFileControl.current.click();
        }
    }

    const formatBoolean = (value?: boolean) => {
        if (value === true) {
            return "Yes";
        }
        else if (value === false) {
            return "No";
        }
        return undefined;
    }

    const exportFile = async () => {
        await refreshUsers();
        let exportUsers = [];
        const exportColumns = _.map(columns, (item) => item.field);

        for (const user of filteredUsers) {
            if (!filteredRowLookup || _.isEmpty(filteredRowLookup) || filteredRowLookup[user.id]) {
                exportUsers.push(user);
            }
        }

        exportUsers = exportUsers.map((x) => {
            return {
                ...x,
                out_wa: formatBoolean(x.out_wa),
                trans_pro: formatBoolean(x.trans_pro),
                telematics: formatBoolean(x.telematics),
                mpg_override: formatBoolean(x.mpg_override),
                follow_opt: formatBoolean(x.follow_opt),
                print: formatBoolean(x.print),
                deleted: formatBoolean(x.deleted),
                surv_status: formatBoolean(x.surv_status),
                inc_discount: formatBoolean(x.inc_discount),
            }
        });

        const csvData = unparse(exportUsers, {
            columns: exportColumns,
        });

        const rows = csvData.split(/\r?\n/);
        rows[0] = rows[0].replace(/([^,]+)/g, (match) => {
            if (match) {
                const column = columnsByField.get(match);
                if (column) {
                    return column.headerName || match;
                }
            }

            return match;
        });

        const blob = new Blob([rows.join('\r\n')], {type: "text/plain;charset=utf-8"});
        FileSaver.saveAs(blob, "waruc_user_export.csv");
    }

    const diffUser = (newUser: IUser, oldUser: IUser) => {
        return _.transform(newUser, (result: any, value, key) => {
            if (!_.isEqual(value, oldUser[key])) {
                result[key] = _.isObject(value) && _.isObject(oldUser[key]) ? _.difference(value, oldUser[key] as never) : value;
            }
        });
    }

    return (
        <>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            <span>
                <input type='file' id='file' onChange={onImportFile.bind(this)} ref={inputFileControl} accept=".csv" style={{display: "none"}} />
                <Button onClick={chooseImportFile}>Import Users</Button>
                <Button onClick={exportFile}>Export Users</Button>
            </span>
            <span style={{alignSelf: 'flex-end'}}>
                <Button onClick={refreshUsers}>Refresh Users</Button>
                <FormControlLabel
                    label="Include Deleted?"
                    style={{alignSelf: 'flex-end'}}
                    control={
                    <Checkbox
                        checked={includeDeleted}
                        onChange={(e) => {
                            setIncludeDeleted(e.target.checked);
                            filterUsers();
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}/>
                    }/>
            </span>
        </div>
        <DataGrid
            rows={filteredUsers}
            columns={columns}
            initialState={{ pagination: { pageSize: 25,},}} // Must be increment of 25 for selector to show up.
            editMode="row"
            experimentalFeatures={{ newEditingApi: true }}
            processRowUpdate={async (newUser: IUser, oldUser: IUser): Promise<IUser> => {
                const diff = diffUser(newUser, oldUser);
                let updatedUser = await Server.updateUser(newUser.id, diff) as any;

                // If the property that was edited isn't in the returned object (when unsetting a value)
                // we need to re-add the null/undefined value in order for the UI to update
                Object.keys(diff).forEach((key) => {
                    if (_.isNil(diff[key]) && _.isUndefined(updatedUser[key])) {
                        updatedUser[key] = diff[key];
                    }
                });

                return updatedUser;
            }}
            onProcessRowUpdateError={(error) => {
                console.log(error);
            }}
            onStateChange={(state: GridState, event: MuiEvent) => {
                filteredRowLookup = state.filter.filteredRowsLookup;
            }}
            getRowClassName={(params => params.row.deleted ? 'MuiDataGrid-deleted-user' : '')}
            />
            <BasicModal open={showImportResults} onClose={() => setShowImportResults(false)}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Import Results
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    User rows added: {importResult?.added || 0}
                    <br/>
                    User rows updated: {importResult?.updated || 0}
                    <br/>
                    User rows skipped: {importResult?.skipped || 0}
                </Typography>
            </BasicModal>
        </>
      );
}

export default Users;
