import { GridColumns, GridValueGetterParams, GridValueSetterParams, ValueOptions } from "@mui/x-data-grid";
import { IUser } from "../models";
import _ from 'lodash';
import { renderBooleanCell } from "./GridBooleanCell";
import { renderEditBooleanCell } from "./GridEditBooleanCell";

const singleSelectGetter = (params: GridValueGetterParams<any, any>): IUser => {
    return _.isNil(params.row[params.field]) ? '' : params.row[params.field];
}

const singleSelectSetter = (field: string, params: GridValueSetterParams<any, any>): IUser => {
    const copy = {...params.row};
    copy[field] = params.value === '' ? undefined : params.value;
    return copy;
}

const booleanProps = {
    type: 'boolean',
    renderCell: renderBooleanCell,
    renderEditCell: renderEditBooleanCell
}

const unsetValue: ValueOptions = { value: '', label: '[unset]'};
export const columns: GridColumns<IUser> & { simField?: boolean } = [
    { field: 'id', headerName: 'ID', width: 280 },
    { field: 'unique', headerName: 'Unique', type: 'number', editable: true, width: 70 },
    { field: 'recruit', headerName: 'Recruiter', type: 'singleSelect', editable: true, width: 120, valueOptions: [
        unsetValue,
        'IPSOS',
        'EI',
        'Yates',
        'Mailing List'
    ], valueGetter: singleSelectGetter, valueSetter: (params) => singleSelectSetter('recruit', params) },
    { field: 'cohort', headerName: 'Target', type: 'singleSelect', editable: true, width: 200, valueOptions: [
        unsetValue,
        'None',
        'Telematics',
        'Flexible Payments',
        'Manual Mileage Exemptions',
        'Reporting Methodology'
    ], valueGetter: singleSelectGetter, valueSetter: (params) => singleSelectSetter('cohort', params) },
    { field: 'first_name', headerName: 'First_Name', editable: true, width: 120 },
    { field: 'last_name', headerName: 'Last_Name', editable: true, width: 160 },
    { field: 'state', headerName: 'State', editable: true, width: 60 },
    { field: 'zip', headerName: 'Zip', editable: true, width: 70 },
    { field: 'phone', headerName: 'Phone', editable: true, width: 140 },
    { field: 'email', headerName: 'Email', editable: true, width: 220 },
    { field: 'ethnicity', headerName: 'KP_Eth', type: 'singleSelect', editable: true, width: 200, valueOptions: [
        unsetValue,
        'White, non-Hispanic',
        'Black, non-Hispanic',
        'Other, non-Hispanic',
        'Hispanic',
        '2+ races, non-Hispanic',
        'Prefer not to answer'
    ], valueGetter: singleSelectGetter, valueSetter: (params) => singleSelectSetter('ethnicity', params) },
    { field: 'ethnicity_ei', headerName: 'EI_Eth', type: 'singleSelect', editable: true, width: 230, valueOptions: [
        unsetValue,
        'African-American or black',
        'Asian or Pacific Islander',
        'Indian subcontinent',
        'Latino or Latina',
        'Native or First Nations',
        'Caucasian or White',
        'Other ',
        'None of the above'
    ], valueGetter: singleSelectGetter, valueSetter: (params) => singleSelectSetter('ethnicity_ei', params) },
    { field: 'ethnicity_other', headerName: 'EI_Eth_O', editable: true, width: 220 },
    { field: 'gender', headerName: 'Gender', type: 'singleSelect', editable: true, width: 110, valueOptions: [
        unsetValue,
        'Male',
        'Female',
        'Other'
    ], valueGetter: singleSelectGetter, valueSetter: (params) => singleSelectSetter('gender', params) },
    { field: 'age', headerName: 'Age', type: 'number', editable: true, width: 70 },
    { field: 'age_7', headerName: 'Age_7', type: 'singleSelect', editable: true, width: 90, valueOptions: [
        unsetValue,
        '18-24',
        '25-34',
        '35-44',
        '45-54',
        '55-64',
        '65-74',
        '75+'
    ], valueGetter: singleSelectGetter, valueSetter: (params) => singleSelectSetter('age_7', params) },
    { field: 'age_4', headerName: 'Age_4', type: 'singleSelect', editable: true, width: 90, valueOptions: [
        unsetValue,
        '18-29',
        '30-44',
        '45-59',
        '60+'
    ], valueGetter: singleSelectGetter, valueSetter: (params) => singleSelectSetter('age_4', params) },
    { field: 'emp', headerName: 'Emp', type: 'singleSelect', editable: true, width: 160, valueOptions: [
        unsetValue,
        'Working full-time',
        'Working part-time',
        'Not working'
    ], valueGetter: singleSelectGetter, valueSetter: (params) => singleSelectSetter('emp', params) },
    { field: 'edu', headerName: 'Edu', type: 'singleSelect', editable: true, width: 245, valueOptions: [
        unsetValue,
        'No high school diploma or GED',
        'High school graduate (high school diploma or the equivalent GED)',
        'Some college or Associate degree',
        'Bachelors degree or higher'
    ], valueGetter: singleSelectGetter, valueSetter: (params) => singleSelectSetter('edu', params) },
    { field: 'marital', headerName: 'Marital', type: 'singleSelect', editable: true, width: 140, valueOptions: [
        unsetValue,
        'Now married',
        'Widowed',
        'Divorced',
        'Separated',
        'Never Married'
    ], valueGetter: singleSelectGetter, valueSetter: (params) => singleSelectSetter('marital', params) },
    { field: 'hh_inc', headerName: 'HH_Inc', type: 'singleSelect', editable: true, width: 210, valueOptions: [
        unsetValue,
        'Less than $10,000',
        '$10,000 t0 $24,999',
        '$25,000 to $49,999',
        '$50,000 to $74,999',
        '$75,000 to $99,999',
        '$100,000 to $149,999',
        '$150,000 or more'
    ], valueGetter: singleSelectGetter, valueSetter: (params) => singleSelectSetter('hh_inc', params) },
    { field: 'hh_l17', headerName: 'HH_l17', type: 'number', editable: true, width: 70 },
    { field: 'hh_m18', headerName: 'HH_m18', type: 'number', editable: true, width: 70 },
    { field: 'hh_size', headerName: 'HH_Size', type: 'number', editable: true, width: 70 },
    { field: 'hh_type', headerName: 'HH_Type', type: 'singleSelect', editable: true, width: 340, valueOptions: [
        unsetValue,
        'One-family house detached from any other house',
        'One-family condo or townhouse attached to other units',
        'Building with 2 or more apartments',
        'Other (mobile home, boat, RV, van, etc)'
    ], valueGetter: singleSelectGetter, valueSetter: (params) => singleSelectSetter('hh_type', params) },
    { field: 'msa', headerName: 'MSA', type: 'singleSelect', editable: true, width: 130, valueOptions: [
        unsetValue,
        'Non-metro',
        'Metro'
    ], valueGetter: singleSelectGetter, valueSetter: (params) => singleSelectSetter('msa', params) },
    { field: 'owner', headerName: 'Owner', type: 'singleSelect', editable: true, width: 300, valueOptions: [
        unsetValue,
        'Owned or being bought by you or someone in your household',
        'Rented for cash',
        'Occupied without payment of rent'
    ], valueGetter: singleSelectGetter, valueSetter: (params) => singleSelectSetter('owner', params) },
    { field: 'num_veh', headerName: 'Num_Veh', type: 'number', editable: true, width: 80 },
    { field: 'lease', headerName: 'Lease', type: 'singleSelect', editable: true, width: 130, valueOptions: [
        unsetValue,
        'Own',
        'Lease'
    ], valueGetter: singleSelectGetter, valueSetter: (params) => singleSelectSetter('lease', params) },
    { field: 'business', headerName: 'Business', type: 'singleSelect', editable: true, width: 130, valueOptions: [
        unsetValue,
        'Personal',
        'Business'
    ], valueGetter: singleSelectGetter, valueSetter: (params) => singleSelectSetter('business', params) },
    { field: 'out_wa', headerName: 'Out_WA', ...booleanProps, editable: true, width: 80 },
    { field: 'recruit_method', headerName: 'Recruit_Method', type: 'singleSelect', editable: true, width: 130, valueOptions: [
        unsetValue,
        'Word of mouth',
        'Online advertising',
        'Read or heard a news story through an organization you belong to',
        'Social media sites, such as Twitter, Facebook, Instagram',
        'Visited the project website at www.waroadusagecharge.org',
        'Read a news story in the newspaper or other news site',
        'Heard a news story or advertisement on the radio',
        'Other'
    ], valueGetter: singleSelectGetter, valueSetter: (params) => singleSelectSetter('recruit_method', params) },
    { field: 'recr_oth', headerName: 'Recr_Oth', editable: true, width: 240 },
    { field: 'occ', headerName: 'Occ', editable: true, width: 240 },
    { field: 'trans_pro', headerName: 'Trans_Pro', ...booleanProps, editable: true, width: 100 },
    { field: 'vin', headerName: 'VIN', editable: true, width: 170 },
    { field: 'license', headerName: 'License', editable: true, width: 90 },
    { field: 'vehyear', headerName: 'VehYear', type: 'number', editable: true, width: 70 },
    { field: 'vehmake', headerName: 'VehMake', editable: true, width: 90 },
    { field: 'vehmodel', headerName: 'VehMod', editable: true, width: 90 },
    { field: 'vehtrim', headerName: 'VehTrm', editable: true, width: 90 },
    { field: 'vehcustom', headerName: 'VehCustom', editable: true, width: 180, simField: true },
    { field: 'telematics', headerName: 'Telematics', ...booleanProps, editable: true, width: 90 },
    { field: 'mpg', headerName: 'MPG', type: 'number', editable: true, width: 80 },
    { field: 'mpg_override', headerName: 'MPG_Override', ...booleanProps, editable: true, width: 110, simField: true },
    { field: 'fuel', headerName: 'Fuel', type: 'singleSelect', editable: true, width: 130, valueOptions: [
        unsetValue,
        'Gasoline',
        'Hybrid',
        'Plug-In Electric',
        'Diesel',
        'Other'
    ], valueGetter: singleSelectGetter, valueSetter: (params) => singleSelectSetter('fuel', params) },
    { field: 'odo', headerName: 'Odo', type: 'number', editable: true, width: 110, simField: true },
    { field: 'mi_est', headerName: 'Mi_Est', type: 'number', editable: true, width: 110, simField: true },
    { field: 'sim_odo', headerName: 'Sim_Odo', type: 'number', editable: true, width: 110, simField: true },
    { field: 'odo_pic', headerName: 'Odo_Pic', type: 'singleSelect', editable: true, width: 130, simField: true, valueOptions: [
        unsetValue,
        'Upload Now',
        'Send Via Text',
        'Scan QR Code',
        'Decline Submission'
    ], valueGetter: singleSelectGetter, valueSetter: (params) => singleSelectSetter('odo_pic', params) },
    { field: 'exempt', headerName: 'Exempt', type: 'singleSelect', editable: true, width: 130, simField: true, valueOptions: [
        unsetValue,
        'None',
        'Less than 200 Miles',
        'More than 200 Miles'
    ], valueGetter: singleSelectGetter, valueSetter: (params) => singleSelectSetter('exempt', params) },
    { field: 'exempt_mi', headerName: 'Exempt_Mi', type: 'number', editable: true, width: 110, simField: true },
    { field: 'inc_discount', headerName: 'Inc_Discount', ...booleanProps, editable: true, width: 100, simField: true },
    { field: 'mi_report', headerName: 'Mi_Report', type: 'singleSelect', editable: true, width: 200, simField: true, valueOptions: [
        unsetValue,
        'Self-Reporting',
        'Vehicle Telematics',
        'Installed Device',
        'Mobile App'
    ], valueGetter: singleSelectGetter, valueSetter: (params) => singleSelectSetter('mi_report', params) },
    { field: 'pay_opt', headerName: 'Pay_Opt', type: 'singleSelect', editable: true, width: 200, simField: true, valueOptions: [
        unsetValue,
        'Pay Today',
        'Four Equal Payments'
    ], valueGetter: singleSelectGetter, valueSetter: (params) => singleSelectSetter('pay_opt', params) },
    { field: 'pay_method', headerName: 'Pay_Method', type: 'singleSelect', editable: true, width: 200, simField: true, valueOptions: [
        unsetValue,
        'Credit/Debit Card',
        'Bank Account',
        'Payment App',
        'Cash / Check'
    ], valueGetter: singleSelectGetter, valueSetter: (params) => singleSelectSetter('pay_method', params) },
    { field: 'print', headerName: 'Print', ...booleanProps, editable: true, width: 80, simField: true },
    { field: 'follow_opt', headerName: 'Follow_Opt', ...booleanProps, editable: true, width: 80, simField: true },
    { field: 'assigned_telematics', headerName: 'Assigned_Telematics', ...booleanProps, editable: true, width: 160 },
    { field: 'assigned_payments', headerName: 'Assigned_Payments', ...booleanProps, editable: true, width: 160 },
    { field: 'assigned_exemptions', headerName: 'Assigned_Exemptions', ...booleanProps, editable: true, width: 160 },
    { field: 'gas_paid', headerName: 'Gas_Paid', type: 'number', editable: true, width: 110, simField: true },
    { field: 'ruc_paid', headerName: 'RUCPaid', type: 'number', editable: true, width: 110, simField: true },
    { field: 'sim_status', headerName: 'Sim_Status', type: 'singleSelect', editable: true, width: 200, simField: true, valueOptions: [
        unsetValue,
        'Welcome',
        'Intro',
        'IntroFAQ',
        'MileageReporting',
        'MileageVerification',
        'Exemption'
    ], valueGetter: singleSelectGetter, valueSetter: (params) => singleSelectSetter('sim_status', params) },
    { field: 'sim_step', headerName: 'Sim_Step', type: 'number', editable: true, width: 100, simField: true },
    { field: 'deleted', headerName: 'Deleted', ...booleanProps, editable: true, width: 80 },
    { field: 'surv_status', headerName: 'Surv_Status', ...booleanProps, editable: true, width: 100 },
  ];

export const columnsByField = new Map(columns.map((x) => [x.field, x]));
export const columnsByLabel = new Map(columns.map((x) => [x.headerName || '', x]));


